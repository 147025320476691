<script>
export default {
  name: "account-menu",

  computed: {
    menuComun() {
      let items = [];
      items.push({
        icon: "heart",
        label: "Favoritos",
        route_name: "account-watching",
      });
      items.push({
        icon: "envelope",
        label: "Buzon de mensajes",
        route_name: "account-inbox",
      });
      for (let index = 0; index < items.length; index++) {
        items[index].active =
          this.$route.name.indexOf(items[index].route_name) !== -1;
      }
      return items;
    },
    menuCuenta() {
      let items = [];
      items.push({
        icon: "heart",
        label: "Favoritos",
        route_name: "account-watching",
      });
      items.push({
        icon: "envelope",
        label: "Buzon de mensajes",
        route_name: "account-inbox",
      });
      items.push({
        icon: "address-card",
        label: "Mi Perfil",
        route_name: "account-profile",
      });
      items.push({
        icon: "cog",
        label: "Mi Configuración",
        route_name: "account-configuration",
      });
      for (let index = 0; index < items.length; index++) {
        items[index].active =
          this.$route.name.indexOf(items[index].route_name) !== -1;
      }
      return items;
    },
    menuAnunciantes() {
      let items = [];
      items.push({
        icon: "layer-group",
        label: "Mis Anuncios",
        route_name: "account-listings",
      });
      items.push({
        icon: "wallet",
        label: "Mis Créditos",
        route_name: "account-credits",
      });
      items.push({
        icon: "users",
        label: "Mi Organización",
        route_name: "account-org",
      });
      for (let index = 0; index < items.length; index++) {
        items[index].active =
          this.$route.name.indexOf(items[index].route_name) !== -1;
      }
      return items;
    },
  },
};
</script>

<template>
  <div class="account__menu">
    <!-- <b-menu>
      <b-menu-list>
        <b-menu-item
          v-for="(item, index) in menuComun"
          :key="index"
          tag="router-link"
          :icon="item.icon"
          :label="item.label"
          :to="{ name: item.route_name }"
          :active="item.active"
        />
      </b-menu-list>
    </b-menu>
    <hr /> -->
    <b-menu>
      <b-menu-list label="Publica">
        <b-menu-item
          v-for="(item, index) in menuAnunciantes"
          :key="index"
          tag="router-link"
          :icon="item.icon"
          :label="item.label"
          :to="{ name: item.route_name }"
          :active="item.active"
        />
      </b-menu-list>
    </b-menu>
    <hr />
    <b-menu>
      <b-menu-list label="Cuenta">
        <b-menu-item
          v-for="(item, index) in menuCuenta"
          :key="index"
          tag="router-link"
          :icon="item.icon"
          :label="item.label"
          :to="{ name: item.route_name }"
          :active="item.active"
        />
      </b-menu-list>
    </b-menu>
    <hr />
    <b-menu>
      <b-menu-list>
        <b-menu-item
          tag="router-link"
          icon="sign-out-alt"
          label="Cerrar sesión"
          :to="{ name: 'logout' }"
        />
      </b-menu-list>
    </b-menu>
    <!-- <router-link :to="{ name: 'logout' }" custom v-slot="{ navigate }">
      <button
        class="button is-small"
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
      >
        Cerrar sesión
      </button>
    </router-link> -->
  </div>
</template>

<style lang="scss" scoped>
hr {
  margin: 0.75rem auto;
}
</style>
